import missingImage from '../assets/images/404.webp'

export default function NoMatch() {
    return (


       

<div className="four04 uk-flex uk-flex-center uk-flex-middle" 
        style={{
            backgroundImage: `url(${missingImage})`
            }}>
           <h1>Page Missing</h1>
        </div>
        
       
    );
}
