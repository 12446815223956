import React, { useEffect, useState, Component } from "react";
import { Link } from "react-router-dom";
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import { Container, Grid, Lightbox } from "uikit-react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import list from "../assets/data/imageList.json";
const transition = { duration: 5, ease: [0.43, 0.13, 0.23, 0.96] };
window.scrollTo(0, 0);
//const [show, setShow] = React.useState(false);
export default function List() {
  const [selectedId, setSelectedId] = useState();
  const handleClick = (id) => {
    setSelectedId(id !== selectedId ? id : null);
  };

  return (
    <div className="uk-container">
      <div className="uk-text-center">
        
      <motion.svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        
              viewBox="0 0 1177.1 480" >

              <motion.g id="exp">
                <path class="st1" id="exp" d="M921.6,53.6" />
                <g>
                <path d="M233.1,287.1h-35.6L186.2,223h-49.4l-11.7,64.1H92L144.4,8.2h36.8L233.1,287.1z M180.8,192L162,72.7L142.3,192H180.8z"/>
		<path d="M345.8,288.1v-95.7c0-18.4-8-27.6-23.9-27.6h-31.4v122.3h-35.2V8.2h68.2c37.1,0,55.7,18.7,55.7,56.1v42.3
			c0,24.8-9.9,39.6-29.7,44.4c10.3,2.2,18.1,7.3,23.2,15.3c5.2,8,7.7,17.4,7.7,28.3v93.6H345.8z M344.5,109.5V63.1
			c0-15.6-7.1-23.4-21.4-23.4h-32.7v97.1h29.3c7.8,0,13.9-2.4,18.2-7.1C342.3,124.9,344.5,118.2,344.5,109.5z"/>
		<path d="M536.3,227.6c0,19-5.4,34.5-16.3,46.5c-10.9,12-26.7,18-47.3,18c-20.7,0-36.7-6.3-48.1-19.1
			c-11.4-12.7-17.2-28.7-17.2-47.9V70.2c0-19.3,5.7-35.2,17.2-47.9C435.9,9.5,452,3.2,472.6,3.2c20.7,0,36.4,6,47.3,18
			c10.9,12,16.3,27.5,16.3,46.5v38.1h-31V66.8c0-9.8-2.7-17.5-8.2-23.2c-5.4-5.7-13.2-8.6-23.2-8.6c-10,0-17.9,2.9-23.4,8.6
			c-5.6,5.7-8.4,13.1-8.4,22v164.1c0,9.2,2.8,16.6,8.4,22.2c5.6,5.6,13.4,8.4,23.4,8.4c10,0,17.8-2.9,23.2-8.6
			c5.4-5.7,8.2-13.5,8.2-23.2v-39.4h31V227.6z"/>
		<path d="M700,287.1h-34.8V163.6H602v123.5h-35.2V8.2H602v123.9h63.2V8.2H700V287.1z"/>
		<path d="M772.8,287.1h-35.2V8.2h35.2V287.1z"/>
		<path d="M939.5,8.2l-55.3,278.9h-34.3L796.3,8.2h36.4l35.2,214.4L904.7,8.2H939.5z"/>
		<path d="M1070.9,287.1H961.7V8.2h109.3V40h-74.5v86.7h54v30.6h-54v98h74.5V287.1z"/>
                </g>
              </motion.g>

              <path className="st0" pathLength="0.5" d="M1156.6,345.7c-86.7,13-174.9,10-262.6,8.8c-231.4-3-463.7,7.4-691.2,49.7c-42.8,8-85.7,17.1-125.9,34.1
		c204.5,3.1,410.7-1.2,610.3-45.7c77.9-17.4,155.6-41.3,222.5-84.8c20.1-13,41.3-33.2,36.4-56.7c-3.7-17.8-21.2-28.9-37.6-36.4
		c-68.1-31.3-144.9-37.2-219.8-38.3c-85.8-1.4-172.3,2.8-256,21.8c-85.3,19.3-168,54.5-235.3,110.4c-2.2,1.8-3.8,4.4-4.2,7.2
		c-1.2,7.7,6,10.9,12.1,12.7c75.6,23.2,155.9,24.5,235,23.8c155.6-1.5,315.6-11.5,456.9-76.7c11.2-5.2,24.5-14.7,18.6-27.5
		c-1.5-3.3-4.1-5.9-7.2-7.8c-41.7-25.8-92.7-29-141.4-29.5c-77.9-0.7-155.7,4.1-233.1,12.6c-113.3,12.4-260.9,95-386.1,109.6
		c-4,0.5-5.2-5.3-1.4-6.4c187.5-57.3,407.7-137.5,581.5-219c0.2-0.1,50.6-19.4,50.9-19.4c217.6-48.2,19.3-58.7-13.6-58.3
		c-27.9,0.3-91.5,3.4-113.9,3.3c-19.4-0.1-38.7,2.8-57.9,5.6c-40.1,5.9-80.1,11.8-120.2,17.8c-54.7,8.2-109.5,16.7-163.3,29.3
		c-92.4,21.6-182,55.2-265.8,99.7"/>


            </motion.svg>

      </div>
      <div className="menu">
        <Lightbox>
          {list.map((art, i) => (
            <React.Fragment  key={i}>
              {selectedId === i && ( 
              <a 
              className="open-lightbox" 

              href={"assets/art/" + art.src}>
                Open
                </a>
              
              )}

        <a className={"open-lightbox hidden start-" + i} href={"assets/art/" + art.src}>Open</a>

              <div
               
                onClick={() => handleClick(i)}
                id={art.id}
                className="menu__item"
              >
                <div className="bg" uk-parallax="y: 0,-100">
                  {art.src.includes(".mp4") ?
                    <video width="750" height="500" muted autoPlay loop >
                      <source src={"assets/art/" + art.src} type="video/mp4" />
                    </video>
                    :
                    <img src={"assets/art/" + art.src} alt={art.text} />
                  }
                </div>
                {
                  //   <Lightbox><a class="uk-button uk-button-default" href={"assets/art/" + art.src}>Open</a></Lightbox>                
                }
                {selectedId === i && (
                  <div>
                    <div className={`list-image`} uk-parallax="y: 0,-100">
                      {art.link == null ?
                        ""
                        :
                        <a href={art.link}>Link ↗ ↗ ↗</a>
                      }
                      {art.src.includes(".mp4") ?
                        <video width="750" height="500" muted autoPlay loop >
                          <source src={"assets/art/" + art.src} type="video/mp4" />
                        </video>
                        :
                        <img src={"assets/art/" + art.src} alt={art.text} />
                      }
                    </div>
                    <br />
                  </div>
                )}
                <span uk-parallax="y: 0,-20" className="menu__item-text">
                  <span className="menu__item-textinner">{art.text}</span>
                </span>
              </div>
            </React.Fragment>
          ))}
        </Lightbox>
      </div>
    </div>
  );
}


