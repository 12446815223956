import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { AnimatePresence, motion } from 'framer-motion'

import { Email } from "react-obfuscate-email";



const today = new Date().getHours();
const year = new Date().getFullYear();




class SiteFooter extends Component {


  render() {
    return (
        <AnimatePresence>
      <motion.div
      
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 4 }}
       
      >
      <div className="">
     
<div className="email">


</div>

        <div className="uk-container">
          <p className="country uk-width-1-2@m uk-align-center uk-text-center">
            Currently working on Kulin Country. I would like
            to acknowledge and pay respect to the Wurundjeri people and culture.
          </p>
        </div>
        <div className="above-footer"></div>
        <div className="footer">
          <div className="uk-container">
            <div className="" uk-grid="true">
              <div className="uk-width-1-1@m uk-text-center">
               
                <div className="logo">
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Scott Bamford"
                      width="250"
                      height="76"
                    />
                  </Link>
                </div>
              
              </div>

              
              
              {" "}
              <div className="uk-width-1-1@m footer-contact">

           {


           }



<div className="say-hi">

Let's Create Something or Just Say Hi
</div>

<br/>             

  <div className="uk-flex">     
  <div>
  <Email email="bam@bamford.design">
 SEND AN EMAIL
 <svg focusable="false" class="base_root__8PzDO" viewBox="0 0 14 14"><path d="M0.978906 2.28H6.97891C9.13891 2.28 10.5389 2.28 11.1789 2.26L0.978906 12.12L2.11891 13.36L12.2789 3.5C12.2589 4.14 12.2589 5.52 12.2589 7.68V13.32L13.8989 11.8V0.759999H2.41891L0.978906 2.28Z"></path></svg>

  </Email>
    </div>      
<div>
<a href="https://www.linkedin.com/in/bamfrd/"> CONNECT ON LINKEDIN 
             <svg focusable="false" class="base_root__8PzDO" viewBox="0 0 14 14"><path d="M0.978906 2.28H6.97891C9.13891 2.28 10.5389 2.28 11.1789 2.26L0.978906 12.12L2.11891 13.36L12.2789 3.5C12.2589 4.14 12.2589 5.52 12.2589 7.68V13.32L13.8989 11.8V0.759999H2.41891L0.978906 2.28Z"></path></svg>
             </a>


</div>


              

            
  </div>
              
              </div>{" "}

            
           
            </div>
            <br/>
           

            <hr />
            <div className="uk-flex">
            
              <div className="uk-width-1-2">
                <p> Some Rights Reserved {year}.   </p>
              </div>
              <div className="uk-width-1-2 uk-text-right@m">



             
             
             
                <p> Have a nice  {
                  today >= 0 && today < 12 ? 'morning 🐝' : ''
                }
                {
                  today >= 12 && today <= 17 ? 'afternoon ✌️' : ''
                }
  
                {
                  today >= 18 && today <= 20 ? 'evening 👯‍♀️' : ''
                }

                {
                  today >= 21 && today < 23 ? 'night 👯‍♀️' : ''
                }
    </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </motion.div>
      </AnimatePresence>
    );
  }
}

export default SiteFooter;
