import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import {
    AnimatePresence,
    motion,
    useTransform,
    useViewportScroll,
} from "framer-motion";
import {
    Icon,
    ListItem,
    Navbar,
    Link as LinkUI,
    Container,
    NavbarSticky,
} from 'uikit-react';
import { Email } from "react-obfuscate-email";

import logo from '../assets/images/logo.svg';

window.onscroll = function() {scrollFunction()};



function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    //document.getElementById("header").addClass = "uk-text-small";
  } else {
   // document.getElementById("header").style.fontSize = "14px";
  }
}

class Header extends Component {


    render() {
        return (
            <NavbarSticky options="cls-inactive: uk-navbar-transparent ">

                <Container>
                    <div className='uk-grid header'>
                        <div className="uk-width-1-3 uk-text-left">
                            <Link className="" to='/'>
                                <img className="logo" src={logo} width="100" height="46" alt="Scott Bamford" />
                            </Link>
                        </div>


                        <div className="uk-width-1-4 uk-visible@s">
                            <h5>About</h5>
                            <p id="header">
                                Designer and artist crafting multimedia experiences that combine art direction and strategic value with human centered design philosophies.
                            </p>
                        </div>

                        <div className="uk-width-1-4 uk-visible@s">
                            <h5>Skills</h5>
                            <p id="header">
                            Interactive and Experience design, Art Direction, Visual Communication and Illustration.</p>                        </div>

                        <div className="uk-width-auto@m uk-visible@s">
                            <h5>Contact</h5>
                            <p>
                                <a href="https://www.linkedin.com/in/bamfrd/"> LinkedIn</a>
                                <br />
                                <Email email="bam@bamford.design">
                                    Email
                                </Email>
                            </p>
                        </div>

                        <Navbar right className="uk-hidden@s uk-width-2-3 uk-flex-right uk-text-right">
                        <LinkUI className="ui-button" toggleOptions="target: #menu;" href="#">
                            🍔 &nbsp;
                        </LinkUI>
                    </Navbar>

                    </div>

                   
                </Container>
            
            </NavbarSticky>

        );
    }
}

export default Header;